import React, {Fragment, Component} from 'react'
import NavbarOne from '../components/NavbarOne'
import GeneralHeader from '../components/GeneralHeader'
import Terms from '../components/Terms'
import Footer from './../components/Footer'

export default class TermsOfService extends Component {

    render(){
        return(
            <Fragment>
                <NavbarOne />
                <GeneralHeader heading={"Terms Of Service"} />
                <Terms />
                <Footer />
            </Fragment>
        )
    }
}
