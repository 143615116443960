import React, {Fragment, Component} from 'react'
import NavbarOne from '../components/NavbarOne'
import GeneralHeader from '../components/GeneralHeader'
import Policy from '../components/Policy'
import Footer from './../components/Footer'

export default class PrivacyPolicy extends Component {

    render(){
        return(
            <Fragment>
                <NavbarOne />
                <GeneralHeader heading={"Privacy Policy"}/>
                <Policy />
                <Footer />
            </Fragment>
        )
    }
}
