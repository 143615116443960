import  React, { Fragment, Component } from 'react'
import {Link} from 'react-router-dom'

export default class Footer extends Component {
	

	render(){

		return(
			<Fragment>

                <footer className="footer">
                    <div className="container">
                    <div className="row gap-y align-items-center">

                        <div className="col-md-3 text-center text-md-left">
                            CURIASHOPS
                        </div>

                        <div className="col-md-6">
                        <div className="nav nav-bold nav-uppercase nav-center">
                            
                            <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                            <Link className="nav-link" to="/terms-of-service">Terms Of Service</Link>
                        </div>
                        </div>

                        <div className="col-md-3 text-center text-md-right">
                            <small>© 2019. All rights reserved.</small>
                        </div>

                    </div>
                    </div>
                </footer>
            </Fragment>
		)
	}
}