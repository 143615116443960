import React, { Fragment, Component } from 'react';
import Routes from './routing/Routes.jsx'

class App extends Component {
  render() {
    return (
      <Fragment>
        <Routes />
      </Fragment>
    );
  }
}

export default App;
