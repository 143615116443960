import  React, { Fragment, Component } from 'react'


export default class ProcessOne extends Component {
	

	render(){

		return(
			<Fragment>
			     <section className="section">
			        <div className="container">
			          <header className="section-header">
			            <small>How Does It Work?</small>
			            <p className="lead">Explore the best ecommerce builder in the market in a short video.</p>
			          </header>


			          <div className="row align-items-center">

			            <div className="col-lg-6">
			              <div className="video-btn-wrapper">
			                <img className="shadow-4 rounded-lg" src="./assets/img/vector/01.png" alt="watch a video" />
			                <a className="btn btn-circle btn-xl btn-info" href="https://youtu.be/FwWMUmWxkS8" data-provide="lightbox"><i className="fa fa-play"></i></a>
			              </div>
			            </div>

			            <div className="col-lg-6">
			              <ol className="step">
			                <li className="step-item">
			                  <div className="step-icon">
			                    <span className="iconbox">1</span>
			                  </div>

			                  <div className="step-content">
			                    <h6><strong>Sign up</strong></h6>
			                    <p>Create a curiashop account with just your phone number.</p>
			                  </div>
			                </li>

			                <li className="step-item">
			                  <div className="step-icon">
			                    <span className="iconbox">2</span>
			                  </div>

			                  <div className="step-content">
			                    <h6><strong>Customize</strong></h6>
			                    <p>Setup the basics of your store. Give it your desired look.</p>
			                  </div>
			                </li>

			                <li className="step-item">
			                  <div className="step-icon">
			                    <span className="iconbox">3</span>
			                  </div>

			                  <div className="step-content">
			                    <h6><strong>Add Inventory</strong></h6>
			                    <p>Add your creations to your store.</p>
			                  </div>
			                </li>
			                <li className="step-item">
			                  <div className="step-icon">
			                    <span className="iconbox">4</span>
			                  </div>

			                  <div className="step-content">
			                    <h6><strong>Make Payment</strong></h6>
			                    <p>Complete the activation of your store with our low prices.</p>
			                  </div>
			                </li>
			                <li className="step-item">
			                  <div className="step-icon">
			                    <span className="iconbox">5</span>
			                  </div>

			                  <div className="step-content">
			                    <h6><strong>Launch Your Website</strong></h6>
			                    <p>That's it.  Your website goes live.</p>
			                  </div>
			                </li>
			              </ol>
			            </div>

			          </div>
			        </div>
			      </section>      
    		</Fragment>
		)
	}
}