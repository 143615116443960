import React, { Component } from 'react';

export default class GeneralHeader extends Component {
    constructor(){
        super();
        this.state = {}
    }

    
    render(){
        return(
            <React.Fragment>
                <header className="header text-white" style={{backgroundColor: 'rgb(240, 90, 36)'}}>
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                    <center>
                        <h1 style={{color: 'white'}} className="display-4">{this.props.heading}</h1>
                       
                    </center>
                    </div>
                    </div>
                </div>
                </header>
                
            </React.Fragment>
        )
    }
}