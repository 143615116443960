import  React, { Fragment, Component } from 'react'
import './icons.css'


export default class FeaturesOne extends Component {
	

	render(){

		return(
			<Fragment>
			     <section className="section p-0">
			        <div className="container-wide">
			          <div className="row no-gap text-center">

			            <div className="col-md-6 bg-gray px-5 py-6 p-md-8">
			              <p className="mb-6"><i className="f-icon fas fa-chart-bar text-warning lead-8"></i></p>
			              <h4 className="mb-5">Inventory Management</h4>
			              <p>Manage your items, stock with ease as our system is designed to your convienence.</p>
			            </div>


			            <div className="col-md-6 px-5 py-6 p-md-8">
			              <p className="mb-6"><i className="f-icon fas fa-truck text-warning lead-8"></i></p>
			              <h4 className="mb-5">Automated Delivery</h4>
			              <p>Worried about delivery? curiashops has got you covered. weve partnered with delivery companies both locally and internationally. rest assured that packages will be delivered to your customers fast and convenient.</p>
			            </div>


			            <div className="col-md-6 px-5 py-6 p-md-8">
			              <p className="mb-6"><i class="fas fa-wallet text-warning lead-8"></i></p>
			              <h4 className="mb-5">Digital Payment Processing</h4>
			              <p>We’ve built curiashops to empower you with the best technologies. We allow you to take mobile money and card payments from your customers in and outside the country.</p>
			            </div>


			            <div className="col-md-6 bg-gray px-5 py-6 p-md-8">
			            	<p className="mb-6"><i className="f-icon fas fa-user-cog text-warning lead-8"></i></p>
			              <h4 className="mb-5">Customizable Design</h4>
			              <p>Design the colors, layout and feel of your design. Tell your brand story with your design. Remind your customers of why you do what you do and who you are</p>

			            </div>


			          </div>
			        </div>
			      </section>      
    		</Fragment>
		)
	}
}