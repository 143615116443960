import React, {Fragment, Component} from 'react';


export default class Terms extends Component{

    componentDidMount(){
        window.scrollTo(0, 0);
    }


    render(){
        return(
            <Fragment>
                <main className="main-content">

                    <section className="section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 mx-auto">
                            <h4><strong>{"CURIASHOPS VENDOR TERMS & CONDITIONS OF SERVICE"}</strong></h4>
                            <p className="lead">Explanation of Terms:</p>
                            <ul className="lead">
                                <li>Vendor: As used in this document, refers to either a Person, a Company or a Brand that sells through the Curiahops platform.</li>
                                <li>Listing: Listings represent the products you sell on curiashops. A listing may have multiple ready-made stocks assigned to it or can be made to order</li>
                                <li>Customer: Anyone who purchases a product from Curiahops</li>
                                <li>Disclaimer: We are not affiliated with any third party services or platforms with which we advertise, sell or integrate into our services</li>
                            </ul>
                            <p className="lead">Roots Digital Limited built CuriaShop as a commercial application. This page is used to inform vendors regarding the use of the platform, that  by choosing to sell your products on the CuriaShops platform through our online e-commerce shop builder, you herein verify to have thoroughly read, understood and thereafter agreed to the following Terms and Conditions of service</p>

                            <ol className="lead">
                                <li> That by signing up for our Vendor Hosting service, You acknowledge us as a collaborative partner in your e-tailing outlet and authorize all our services.</li>
                                <li> That upon registration onto the CuriaShops platform, You will provide us with correct and recent details of the following:
                                    <ul>
                                        <li>Your current and active email address</li>
                                        <li>Your mobile phone number</li>
                                        <li>Your valid business address</li>
                                    </ul>
                                </li>
                                <li>You herein testify to the ownership of Your said Brand of Products; that You ensure the proper and due acknowledgment of all artistic contributors to your work; and that You in no way violate any copyright laws or commit any form of intellectual theft. Vendors who are found to be violating any Copyright Laws or engaging in any form of Intellectual or Brand theft would immediately be banned from our platform</li>
                                <li> You have properly understood our pricing and have agreed to it.</li>
                                <li> You will always keep track of products placed on the site so as to ensure users are aware when a product runs out of stock. This does not apply to made-to-order listings</li>
                                <li>That you accept our settlement policy: settlement for sold products would be made only after the order has been fulfilled whereby the vendor will be settled on a request basis or automatically depending on which process the vendor chooses.  You will receive a statement for each completed settlement detailing the transaction and any deductions by the payment platform.</li>
                                <li> You can withdraw from the service whenever you choose but will only do so after sending us a 3-day notice in advance. Withdrawing from our hosting service means You will no longer provide Your products for fulfillment. As such, all listings belonging to Your brand will be removed from our platform and we will terminate any collaborative relations in managing any third-party marketplace listings. It should be noted that withdrawing from any of our services do NOT merit any refund of paid Fees whatsoever. </li>
                                <li>CuriaShop does not hold stock. You are solely responsible for holding and safeguarding your stock until an order for the product is made</li>
                                <li>You are responsible for the primary packaging of your products. We advise proper brand packaging for better brand engagement.</li>
                                <li> You are to make regular updates to your inventory when a listing develops a fault or becomes unavailable for any reason so that it be temporarily or permanently removed.</li>
                                <li>We reserve the right to return the package for repair</li>
                                <li>We have the sole mandate of choosing Vendors to host on our platform. Factors we consider when accepting Vendors are:
                                    <ul>
                                        <li>Uniqueness and Creativity of the brand</li>
                                        <li>Quality</li>
                                        <li>Branding</li>
                                    </ul>
                                </li>
                                <li>
                                We reserve the right, in our sole discretion to terminate a vendor when our services Curiashop services are used or the sale or supply of:
                                <ul>
                                    <li>Tobacco Products</li>
                                    <li>Prescription Drugs</li>
                                    <li>Drugs and drug paraphernalia</li>
                                    <li>Weapons (including without limitation, knives, guns, firearms or ammunition)</li>
                                    <li>Satellite and cable TV descramblers</li>
                                    <li>Pornography, adult material, material which incites violence, hatred, racism or which is considered obscene </li>
                                    <li>Government IDs and licenses including replicas and novelty items and any counterfeit products</li>
                                    <li>Unlicensed or illegal lotteries or gambling services (including without limitation the use of or participation in illegal gambling houses)</li>
                                    <li>Unregistered charity services</li>
                                    <li>Items which encourage or facilitate illegal activities</li>
                                    <li>Prepaid debit cards or other stored value cards that are not associated with a particular merchant and are not limited to purchases of particular products or services</li>
                                    <li>Third party processing or payment aggregation products or services</li>
                                    <li>Multi-level marketing, pyramid selling or Ponzi schemes, matrix programmes or other “get rich quick” schemes or high yield investment programmes</li>
                                    <li>Goods or services that infringe the intellectual property rights of a third party.</li>
                                    <li>Un-coded/miscoded gaming</li>
                                    <li>Timeshares or property reservation payments (On and Off Plan)</li>
                                </ul>
                                </li>

                                <li> You herein consent to the publishing of Your Brand and contact information as well as social media pages on the Internet.</li>
                                <li>You are responsible for the following activities involving product listing: snapshots,quantity, pricing, and product descriptions. Your Product pictures and product descriptions must be to our Specifications</li>
                                <li> That we have the right to change any of our service offerings as and when we deem fit. We will do our best to make sure Vendors are well informed prior to any changes being effected. </li>
                                <li> That we reserve the right to temporarily dismiss or permanently ban from the services of our platform, any Vendor who fails to comply with any of the above Terms and Conditions of Service. </li>
                                <li> Vendors will receive an email invoice when payment is due for the collection of outstanding listing fees. Accepted payment methods are Visa/Mastercard, Mobile Money. Vendorsan also opt to pay in person. Failure to complete payment after a week of receiving the invoice will prompt deactivation of the online shop which payment has not yet been received</li>
                                <li> Vendors who offer refunds are advised to provide their refund policies on their online e-commerce website. CuriaShops will not be responsible for returns and refunds as this is entirely between the vendor and the user</li>
                            </ol>


                            <a class="btn btn-xs btn-primary btn-success" href="/assets/files/terms.pdf" download>Download Terms Of Service</a>
                            </div>
                        </div>
                    </div>
                    </section>

                </main>
            </Fragment>
        )
    }
}