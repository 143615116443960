import  React, { Fragment, Component } from 'react'
import NavbarOne from './../components/NavbarOne.jsx'
import Header from './../components/Header.jsx'
import FeaturesOne from './../components/FeaturesOne.jsx'
import PricingTwo from './../components/PricingTwo.jsx'
import FAQ from './../components/FAQ.jsx'
import ProcessOne from './../components/ProcessOne.jsx'
import Footer from './../components/Footer.jsx'
import ScrollableAnchor from 'react-scrollable-anchor'

export default class Home extends Component {
	

	render(){

		return(
			<Fragment>
				<NavbarOne />
				<ScrollableAnchor id={'home'}><Header /></ScrollableAnchor>
				<ScrollableAnchor id={'features'}><FeaturesOne /></ScrollableAnchor>
				<ScrollableAnchor id={'process'}><ProcessOne /></ScrollableAnchor>
				<ScrollableAnchor id={'faq'}><FAQ /></ScrollableAnchor>
				<Footer />
    		</Fragment>
		)
	}
}