import  React, { Fragment, Component } from 'react'

export default class Header extends Component {
	

	render(){

		return(
			<Fragment>
			    <header className="header h-fullscreen">
			      <div className="container">
			        <div className="row align-items-center h-100">

			          <div className="col-lg-5">
			            <h1 className="fw-600">Let's Go <span style={{color: '#f05a24'}}>Global</span> Together</h1>
			            <p className="lead mt-5 mb-8">Give your customers the best possible online shopping experience. Bring them in from Instagram, Twitter and Facebook. Use one platform to sell products to anyone, anywhere</p>
			            <p className="gap-xy">
			              <a className="btn btn-round btn-primary mw-200" href="/signup" style={{background: '#f05a24', borderColor: '#f05a24'}}>Sign up</a>
			            </p>
			          </div>

			          <div className="col-lg-6 ml-auto d-none d-lg-block">
			          	<div className="slider-center-focused" data-autoplay="true" data-provide="slider" data-center-mode="true" data-center-padding="20%">
		                	<div className="p-2"><img src="./assets/img/vector/01.png" alt="img"/></div>
		                	<div className="p-2"><img src="./assets/img/vector/02.png" alt="img"/></div>
		                	<div className="p-2"><img src="./assets/img/vector/03.png" alt="img"/></div>
		                	<div className="p-2"><img src="./assets/img/vector/04.png" alt="img"/></div>
		                	<div className="p-2"><img src="./assets/img/vector/05.png" alt="img"/></div>
		              	</div>
			          </div>

			        </div>
			      </div>
			    </header>
    		</Fragment>
		)
	}
}