import  React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom';


export default class PricingTwo extends Component {
	

	render(){

		return(
			<Fragment>
			    <section className="section text-white" style={{backgroundColor: '#f05a24'}}>
			        <div className="container">
			          <h2 className="text-center">Choose your pricing plan</h2>
			          <br /><br /><br />

			          <div className="row gap-y">

			            <div className="col-lg-4">
			              <div className="pricing-2">
			                <h2 className="price"><span className="price-unit">GHS</span> 180</h2>
			                <h6 className="plan-name">3 Months</h6>
			                <br />
			                <Link className="btn btn-round btn-outline-light w-200" to="/login">Sign up</Link>
			              </div>
			            </div>


			            <div className="col-lg-4">
			              <div className="pricing-2">
			                <h2 className="price"><span className="price-unit">GHS</span> 370</h2>
			                <h6 className="plan-name">6 Months</h6>
			                <br />
			                <Link className="btn btn-round btn-light w-200" to="/login">Sign up</Link>
			              </div>
			            </div>


			            <div className="col-lg-4">
			              <div className="pricing-2">
			                <h2 className="price"><span className="price-unit">GHS</span> 590</h2>
			                <h6 className="plan-name">1 year</h6>
			                <br />
			                <Link className="btn btn-round btn-outline-light w-200" to="/login">Sign up</Link>
			              </div>
			            </div>

			          </div>

			        </div>
			      </section>
    		</Fragment>
		)
	}
}