import  React, { Fragment, Component } from 'react'
import Media from 'react-media'

export default class NavbarOne extends Component {
	

	render(){

		return(
			<Media query="(min-width: 1024px)">
                {matches => 
                matches ? (
				<Fragment>
				    <nav className="navbar navbar-expand-lg navbar-dark" data-navbar="fixed" style={{backgroundColor: 'white', marginTop: '-0.8em'}}>
			            <div className="container">
			              <section className="navbar-mobile">
			                <nav className="nav nav-navbar mr-auto">
			                  <a className="nav-link active" href="/#">Home</a>
			                  <a className="nav-link" href="/#features">Features</a>
			                  <a className="nav-link" href="/#process">Process</a>
			                  <a className="nav-link" href="/#faq">FAQ</a>
			                </nav>

			                <div>
			                  <a className="btn btn-sm btn-primary ml-lg-5 mr-2" style={{background: '#f05a24', borderColor: '#f05a24'}} href="/login">Login</a>

							  <a className="btn btn-sm btn-primary ml-lg-5 mr-2" style={{background: '#f05a24', borderColor: '#f05a24'}} href="/signup">Signup</a>
			                 
			                </div>
			              </section>

			            </div>
			          </nav>
	    		</Fragment>
	    		): (
	    		<Fragment>
				    <nav className="navbar navbar-expand-lg navbar-dark" data-navbar="fixed" style={{backgroundColor: '#f05a24', marginTop: '-0.8em'}}>
                    <div className="container">
                        <div className="navbar-left" style={{width: '50px'}}>
                        </div>

                        <span>
                        <button className="btn btn-xs btn-secondary" data-toggle="offcanvas" data-target="#offcanvas-menu"><i className="fas fa-align-justify" style={{fontSize: 'large'}}></i></button>
                                
                                
                        </span>
                    </div>
                    </nav>



                    {/*offcanvas sidemenu*/}
                  <div id="offcanvas-menu" className="offcanvas text-white w-300 px-7" data-animation="slide-left" style={{backgroundColor: 'rgba(0,0,0,0.9)'}}>
                      <button type="button" className="close position-static px-0" data-dismiss="offcanvas" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>

                      <nav className="nav nav-lead flex-column my-7">
                        <a className="nav-link active" href="/#">Home</a>
			            <a className="nav-link" href="/#features">Features</a>
			            <a className="nav-link" href="/#process">Process</a>
			            <a className="nav-link" href="/#faq">FAQ</a>
                      </nav>
                    </div>
	    		</Fragment>
	    		)
	    	}
	    </Media>
		)
	}
}