import  React, { Fragment, Component } from 'react'


export default class FAQ extends Component {
	

	render(){

		return(
			<Fragment>
			    <section className="section">
			        <div className="container">
			          <header className="section-header">
			            <small>FAQ</small>
			            <h2>Frequently Asked Questions</h2>
			            <hr />
			            <p>Got a question? We've got answers. If you have some other questions, see our support center.</p>
			          </header>


			          <div className="row">
			            <div className="col-md-8 mx-auto">

			              <div className="accordion accordion-connected accordion-arrow-right" id="accordion-1">
			                <div className="card">
			                  <h5 className="card-title">
			                    <a  className="collapsed" data-toggle="collapse" href="#collapse-1-1">Is this a secure site for purchases?</a>
			                  </h5>

			                  <div id="collapse-1-1" className="collapse" data-parent="#accordion-1">
			                    <div className="card-body">
			                      Absolutely! We work with top payment companies which guarantees your safety and security. All billing information is stored on our payment processing partner which has the most stringent level of certification available in the payments industry.
			                    </div>
			                  </div>
			                </div>


			                <div className="card">
			                  <h5 className="card-title">
			                    <a className="collapsed" data-toggle="collapse" href="#collapse-1-2">How long are your contracts?</a>
			                  </h5>

			                  <div id="collapse-1-2" className="collapse" data-parent="#accordion-1">
			                    <div className="card-body">
			                      Currently, we offer monthly and yearly subscription. You can upgrade or cancel your account at any time with no further obligation.
			                    </div>
			                  </div>
			                </div>


			                <div className="card">
			                  <h5 className="card-title">
			                    <a className="collapsed" data-toggle="collapse" href="#collapse-1-3">Can I cancel my subscription?</a>
			                  </h5>

			                  <div id="collapse-1-3" className="collapse" data-parent="#accordion-1">
			                    <div className="card-body">
			                      You can cancel your subscription anytime in your account. Once the subscription is cancelled, you will not be charged for the next period. You will continue to have access to your account until your current subscription expires.
			                    </div>
			                  </div>
			                </div>


			              </div>

			            </div>
			          </div>

			        </div>
			      </section>
    		</Fragment>
		)
	}
}