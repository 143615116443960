import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Home from './../views/Home.jsx'
import TermsOfService from './../views/TermsOfService'
import PrivacyPolicy from './../views/PrivacyPolicy'

export default class Routes extends Component {
	constructor(){
		super();
		this.state = {
			data: null
		}
	}


	render(){

		const homePage = () => (
			<Home />
		)

		const privacyPolicy = () => (
			<PrivacyPolicy />
		)

		const termsOfService = () => (
			<TermsOfService />
		)

		return(
			<React.Fragment>
	            <Route exact path="/" component={homePage} />
	            <Route exact path="/terms-of-service" component={termsOfService} />
	            <Route exact path="/privacy-policy" component={privacyPolicy} />
        	</React.Fragment>
		)
	}
}