import React, {Fragment, Component} from 'react';


export default class Policy extends Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <Fragment>
                <main className="main-content">
                    <section className="section">
                    <div class="container">
                        <div class="row">
                        <div class="col-md-10 mx-auto">
                            <h4><strong>{"CURIASHOPS PRIVACY POLICY"}</strong></h4>
                            <p className="lead">Roots Digital Limited built the CuriaShop as a Commercial application.This page is used to inform users of our policies regarding the collection, use, and disclosure of personal and corporate  Information if anyone decides to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as stated in our Terms of Service, which is accessible at Curia Shop unless otherwise defined in this Privacy Policy. </p>

                            <h4><strong>{"INFORMATION COLLECTION AND USE"}</strong></h4>
                            <p className="lead">For a better experience, while using our service, we may require you to provide us with certain   information. The   information requested will be retained by us and used as described in this privacy policy. The information will include
                                <ol className="lead">
                                    <li>Shop Name</li>
                                    <li>Description of the shop</li>
                                    <li>Location of Shop</li>
                                    <li>Phone Number</li>
                                    <li>Email</li>
                                </ol>
                            </p>

                            <p className="lead">The app does use third-party services that may collect information used to identify you. Links to the privacy policy of third party service providers are as follows</p>
                            <ul className="lead">
                                <li>Amazon Web Services</li>
                                <li>MTN mobile Money</li>
                                <li>Curia Delivery Routing Service</li>
                            </ul>

                            <h4><strong>{"LOG DATA"}</strong></h4>
                            <p className="lead">We want to inform you that whenever you use our Service, in the case of an error in the web app we collect data and information called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, the time and date of your use of the Service, and other statistics</p>

                            <h4><strong>{"SERVICE PROVIDERS"}</strong></h4>
                            <p className="lead">We may employ third-party companies and individuals due to the following reasons</p>
                            <ul className="lead">
                                <li>To facilitate our service</li>
                                <li>To perform service-related services</li>
                                <li>To assist us in analyzing how our Service is used</li>
                            </ul>
                            <p className="lead">We want to inform users of this Service that these third parties access to your personal information is highly restricted for access to your personal and corporate Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose</p>

                            <h4><strong>{"SHOP ACCOUNT AND SECURITY"}</strong></h4>
                            <p className="lead">When you set up a shop account, you are the sole authorized user of the account, You shall be responsible for maintaining the secrecy and confidentiality of your password and for all activities that transpire on or within your account. It is your responsibility for any act or omission of any users that access your account information that, if undertaken by you, would be deemed a violation of the Terms of Service(TOS), It shall be your responsibility to notify Roots Digital Limited immediately if you notice any unauthorized access or use of your account or password or any other breach of security. We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security</p>

                            <h4><strong>{"LINKS TO OTHER SITES"}</strong></h4>
                            <p className="lead">This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services</p>

                            <h4><strong>{"CHILDREN'S PRIVACY"}</strong></h4>
                            <p className="lead">These Services do not address anyone under the age of 13. We do not knowingly collect personal information from children under the age of 13. In the case where we discover that a child under the age of 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions. We will also not be held responsible for any child under the said age above who provides us with wrong information in order to gain access to the services or the application.</p>

                            <h4><strong>{"CHANGES TO THIS PRIVACY POLICY"}</strong></h4>
                            <p className="lead">We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on our website and in the curiashops platform. These changes take effect immediately after they have been posted on this page</p>

                            <h4><strong>{"CONTACT US"}</strong></h4>
                            <p className="lead">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us on info@rootsdigital.tech</p>

                           
                            <a class="btn btn-xs btn-primary btn-success" href="/assets/files/privacy.pdf" download>Download Privacy Policy</a>
                        </div>
                        </div>
                    </div>
                    </section>
                </main>
            </Fragment>
        )
    }
}